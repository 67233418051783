//@import url( system/style.css );
/* THEME STYLE */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import 'variables';
@import 'colors';
@import 'auth';

html {
    font-size: 15px;
}

@media (max-width: 767px) {
    html {
        font-size: 14px;
    }
}
body {
    font-size: 1rem;
    line-height: 1.5;
    font-family: $font-family;
    overflow-x: hidden;
    position: relative;
    color: $accent-color-3!important;
    background:#f2f2f2;
    font-family: $font-family !important;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-family !important;
}
a {
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}
.secondary {
    background: $accent-color-2!important;
}
.font-size-30 {
    font-size: 30px;
}
.font-bold {
    font-weight: 700;
}
/* TOP BAR - LOGO AND PHONE */
.info-contacto {
    margin-top: 2rem;
    font-weight: bold;
}

.logo-login{
    height: 150px;
}
.logo-header {
    height: 100px
}
.react-datepicker-wrapper {
width: 100%;
}

.departure-datepicker {
    width: 100%;
}

h1 {
    font-size: 2rem !important;
    line-height: 2rem;
}
h2 {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.noprint {
    @media print {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .departure-datepicker {
      margin-left: -150px;

      .react-datepicker__triangle {
        right: 5px!important;
        left: 0;
      }
    }
  }

/* CONTENEDOR FLEX PARA LOS DATOS BASICOS DE TRANSPORTACION*/
.contenedor-flex {
    font-size: 16px;
    display: flex;
    flex-direction: row;
}
#breadcrumbs {
    height: 22px;
    background-color: $accent-color-2;
}
.info-bread {
    font-size: 1.3rem;
    color: white;
    padding:5px 0;
    flex-grow: 1;
}
.select-search {
    width: 100%!important;
    background: #f1f1f1!important;
}

.badge {
    font-size: 1rem!important;
    margin-right: 1rem;
    background:$accent-color-2!important;
}

#top-bar {
    padding: 10px 0;
    li {
        a {
            font-size: 1.3rem;
        }
    }
}
#booking-form {
    [type=color],
    [type=date],
    [type=datetime-local],
    [type=datetime],
    [type=email],
    [type=month],
    [type=number],
    [type=password],
    [type=search],
    [type=tel],
    [type=time],
    [type=url],
    [type=week],
    textarea,
    select {
        background: #fff !important;
        border: 1px solid #efefef !important;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    }
    .input-text{
        background: #fff !important;
        border: 1px solid #efefef !important;
    }
    input:disabled,
    input[readonly],
    textarea:disabled,
    textarea[readonly] {
        background: #fff;
    }

    form {
        svg {
            margin-top: .7rem;
            margin-left: -.5rem;
        }
    }
    .arrival-information {
        border-right: 1px solid lightgray;
    }
    .booking-box select,
    .booking-box input {
        border: 1px solid #d9d9d9!important;
        background: #fff;
    }
    .bg-icons {
        color: #fff;
        background: $accent-color-2;
        border: none;
    }
    .icon-alert{
        font-size: 2rem;
    }
    
    select {
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
        //background: url('../img/br_down.png') no-repeat right #f1f1f1!important;
        background-position-x: 96%!important;
    }

    #img {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .payment {

    }
}

.heading-title-search {
    border-bottom: 1px solid lightgray;
}

.callout {
    border: none!important;
    border-radius: 4px;
}
.callout.alert{
    color:$accent-color-1;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}
/*     formularios******************************* */

.input-group {
    flex-wrap: nowrap !important;
}

#formAction {
    background: #fff;
}

input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
    background: #fff;
    cursor: pointer!important;
}

.input-group-label,
.input-group-ArrCalendar,
.input-group-time,
.input-group-DeparCalendar {
    text-align: center;
    padding: 0 1rem;
    background: #e5e5e5;
    color: #0a0a0a;
    border: 1px solid #707070;
    white-space: nowrap;
    width: 1%;
    height: 2.4375rem;
}

.input-group-button,
.input-group-field,
.input-group-ArrCalendar,caa
.input-group-time,
.input-group-DeparCalendar {
    margin: 0;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle;
}

.input-group-label:last-child {
    border-left: 0;
}
.border-bottom {
    border-bottom: 1px solid $accent-color-3;
}


.info-bar {
     border-radius: 5px;
     margin: 4rem 0;
 }
/*fin*/
/* alineacion en tablas */
.table {
    td ,th {
        vertical-align: middle!important;
    }
}
/* carrucel home page*/
.carousel-caption h5 {
    font-weight: bold;
}
.details {
    border: 1px solid #f1f1f1;
    color: #000;
    background: #f0f0f0;
    color: #000;
    margin-bottom: 2rem;
    @media screen and (max-width:480px) {
        background: #f1f1f1;
    }
}
/**********************/
.datepickers-container {
    z-index: 99999;
  }
  .datepicker--time, .datepicker--time-current-ampm {
    font-weight: 700;
    background: $accent-color-2;
    color:white!important;
  }
  .datepicker--time-current-hours:after, .datepicker--time-current-minutes:after {
    background: $accent-color-3!important;
  }
  /***********************/
  .banner-cancellation-free {
    border: 2px solid green!important;
    padding: 20px;
    font-weight: 700;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    background-color: white !important;
    border: none !important;
    border-radius: unset !important;
    height: 15px;
    min-height: 32px;
    //box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);

    [type='text'] {
        margin: 0 !important;
        height: 1rem !important;

    }
    [type='text']:focus{
        outline: none;
        border: none;
        background-color: transparent ;
        box-shadow: none ;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    }

    .css-1hb7zxy-IndicatorsContainer {
      height:30px !important;
    }
    .css-1okebmr-indicatorSeparator {
      border: none !important;
    }

    .css-1wa3eu0-placeholder {
      margin: 0 !important;
      padding: 0 !important;
    }
    .css-b8ldur-Input {
      input[type=text] {
        background: transparent !important;
        border: none !important;
      }
    }
  }

  .politicas {
    height: 150px;
    overflow: scroll;
  } 

  .modal-footer {
    .button {
      margin-left: 1rem;
    }
  }
  /**   CAMBIOS PARA HACER EL MODAL SCROLLEABLE***/

  .ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReactModal__Content {
  outline: none;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  position: relative;
}
.css-tlfecz-indicatorContainer {
    svg {
        margin-top:.5rem !important;
        margin-left: .1rem !important;
        color: $accent-color-1;
    }
}
.css-1okebmr-indicatorSeparator {
    background: none !important;
}
.line-through {
    text-decoration: line-through !important;
    color: brown;
}
.promotion_text {
    color: brown;
}

/* moddal */
.reservation-table > .modal-header {
    background-color: #00718a !important;
}

.reservation-table > .modal-header > .modal-title {
    color: white !important;
}
.select-search{
    border: thin solid #cacaca;
}
.pagination {
    .disabled{
        padding: 0 !important;
    }
}
.action {
    display: flex;
    border: none !important;
    align-items: center;
    justify-content: center;
}
.info-fix > p {
    margin-bottom: 0rem;
}
.info-fix > div > p {
    margin-bottom: 0rem;
}
.modal-modify {
    p {
        margin-bottom: 0;
    } 
}
.select-search {
    margin-bottom: 1rem;
}